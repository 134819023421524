import 'react-tabs/style/react-tabs.scss';

import withReduxStore from '@lib/with-redux-store';
import withError from '@lib/withError';
import Layout from '@src/components/layout';
import { KeynesLALSiteVisitPixel, KeynesUniversalPixel } from '@src/components/pixel/KeynesPixel';
import WebChat from '@src/components/WebChat';
import { YWXI_main } from '@src/components/ywxi/scripts';
import Styles from '@src/scss/index.scss';
import '@src/scss/index.scss';
import '../styles/globals.scss';
import metaBanner from '@src/static/images/1800battery-meta-banner.png';
import { affirmInit } from '@util/affirm';
import { BingLoad } from '@util/bing';
import { mntnInit } from '@util/mntn';
import Cookies from 'js-cookie';
import App from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import { withRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import NProgress from 'nprogress';
import React from 'react';
import TagManager from '@sooro-io/react-gtm-module'
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { GroupByBeacons } from '@util/groupByBeacons';
import { TermlyInit } from '@util/termly';

 // Need to Create Site Variable and not forgot for autoparts.com GTM / Segment /Etc..
const tagManagerArgs = {
    gtmId: 'GTM-PNHHSL5',
    events: [
        {
            event: 'consent_loaded',
            consentAnalytics: true,
            consentAds: true,
            consentPreferences: true,
        },
    ],
}

var _affirm_config = '';

var timer = null;
NProgress.configure({
	parent: 'body',
	template:
		'<div class="bar-wrapper"><div class="bar" role="bar"><div class="peg"></div></div><div class="spinner-container"><div class="spinner" role="spinner"><div class="spinner-icon"></div> <span>Loading... Please wait.</span></div></div></div>',
	easing: 'ease-in-out',
});

Router.events.on('routeChangeStart', () => {
	clearTimeout(timer);
	timer = setTimeout(NProgress.start, 500);
});

Router.events.on('routeChangeComplete', url => {
	clearTimeout(timer);
	NProgress.done();

	var pagePath = url;

	var searchString = pagePath.substring(pagePath.lastIndexOf('/') + 1);

	if (pagePath.indexOf('/search/') === 0) {
		pagePath = `${pagePath}?q=${searchString}`;
	}
	if (pagePath.indexOf('/category/') === 0) {
		pagePath = `${pagePath}?cat=${searchString}`;
	}
	if (process.env.NODE_ENV == 'production') {
		window.analytics.page(pagePath);
	}
});

Router.events.on('routeChangeError', () => {
	clearTimeout(timer);
	NProgress.done();
});

class MyApp extends App {
	constructor() {
		super(...arguments);
		this.state = { hasError: false, errorEventId: undefined, isLoading: true, masterProvider: { isActive: false } };
	}

	async componentDidMount() {
		this.setState({ isLoading: false });
		if (typeof window !== 'undefined') {
			affirmInit(process.env.NEXT_PUBLIC_AFFIRM_PUBLIC_KEY, process.env.NODE_ENV);
			if (process.env.NODE_ENV === 'production') {
				mntnInit();
			}
		}
		TagManager.initialize(tagManagerArgs);

		const masterProvider = Cookies.get('masterprovider');

		if (masterProvider) {
			this.setState({
				masterProvider: JSON.parse(masterProvider),
			});
		}
		 
	}

	render() {
		const { masterProvider } = this.state;
		const { Component, pageProps, store, router } = this.props;

		let title = '1-800-Battery';
		let description = "North America's Largest Battery Store";
		let favIcon = null;
		if (masterProvider && masterProvider.isActive) {
			title = masterProvider.name;
			description = masterProvider.footerHeadline;
			favIcon = masterProvider.favoriteIcon;
		}

		

		return (
			<>
				<NextSeo
					title={`${title} - ${description}`}
					description={description}
					openGraph={{
						title: `${title} - ${description}`,
						description: description,
						images: [
							{
								url: metaBanner.src,
							},
						],
					}}
				/>
				<Head>
					{favIcon ? (
						<link rel="shortcut icon" href={favIcon} />
					) : (
						<link rel="shortcut icon" href="../../static/images/favicon.png" />
					)}
				</Head>
				<Provider store={store}>
					<Head>
						<meta name="viewport" content="width=device-width, initial-scale=1" />
						<style dangerouslySetInnerHTML={{ __html: Styles }} />
					</Head>

					<Toaster position="top-right" />
					<TermlyInit />
					<KeynesUniversalPixel />
					<KeynesLALSiteVisitPixel />
					<YWXI_main />
					<WebChat />
					<BingLoad />
					<GroupByBeacons />
					

					<PersistGate loading={null} persistor={store.__PERSISTOR}>
						{() => (
							<Layout store={store}>
								<Component
									router={router}
									masterProvider={masterProvider}
									{...pageProps}
									analytics={global.analytics}
									affirm_config={_affirm_config}
								/>
							</Layout>
						)}
					</PersistGate>
				</Provider>
			</>
		);
	}
}

export default withError(withReduxStore(withRouter(MyApp)));
